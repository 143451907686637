import {
  getServiceTypesGet,
  getBusinessGet,
  getBusinessRatingsGet,
  getSearchBusinessesGet,
} from "services/Endpoints/EserviceBook/BusinessesEndpoints";

import ActionTypes from "./ActionTypes";
import { tryCatchJson } from "utils/FetchUtils";
import { setServiceTypesArrayRedux } from "./serviceActions";

export async function getServiceTypesAction(dispatch) {
  const fetchFunc = getServiceTypesGet;
  const responseData = await tryCatchJson(fetchFunc);
  if (!!responseData && responseData.success) {
    dispatch(setServiceTypesArrayRedux(responseData.items));
    return responseData.items;
  }
}

export async function getSearchBusinessesAction(
  searchParams,
  shouldAppend,
  dispatch
) {
  const fetchFunc = () => getSearchBusinessesGet(searchParams);
  const responseData = await tryCatchJson(fetchFunc);
  if (!!responseData && responseData.success) {
    const reduxAction = shouldAppend
      ? appendBusinessSearchResultsRedux
      : setBusinessSearchResultsRedux;
    const { businesses, allResultsCount } = responseData.value;
    dispatch(reduxAction(businesses, allResultsCount));
  }

  return !!responseData && responseData.success;
}

export const getBusinessRatingsAction = async (businessId) => {
  const fetchFunc = () => getBusinessRatingsGet(businessId);
  const responseData = await tryCatchJson(fetchFunc);
  return responseData
};

export async function getBusinessAction(id, latitude, longitude) {
  const fetchFunc = () => getBusinessGet(id, latitude, longitude);
  const responseData = await tryCatchJson(fetchFunc);
  return responseData;
}

const setServiceTypesRedux = (serviceTypes) => {
  return { type: ActionTypes.SET_SERVICE_TYPES_ACTION, serviceTypes };
};

const setBusinessSearchResultsRedux = (searchResults, allResultsCount) => {
  return {
    type: ActionTypes.SET_BUSINESS_SEARCH_RESULTS_ACTION,
    searchResults,
    allResultsCount,
  };
};

const appendBusinessSearchResultsRedux = (searchResults, allResultsCount) => {
  return {
    type: ActionTypes.APPEND_BUSINESS_SEARCH_RESULTS_ACTION,
    searchResults,
    allResultsCount,
  };
};

const setBusinessRatingsRedux = (businessId, businessRatings) => {
  return {
    type: ActionTypes.SET_BUSINESS_RATINGS_ACTION,
    businessId,
    businessRatings,
  };
};
