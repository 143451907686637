import Logo from "shared/Logo/Logo";
import { CustomLink, ERecord, Vehicle } from "data/types";
import React, { useEffect, useState } from "react";
import blueLogo from "../Logo/E_logo_blue_512x512.png";
import btnIosPng from "images/btn-ios.png";
import btnAndroidPng from "images/btn-android.png";
import { Link, useLocation, useParams } from "react-router-dom";
import AppConfig from "config/AppConfig";
import { downloadAppClicked } from "services/Endpoints/EserviceBook/SmsLinkEndpoints";
import { getERecordWithToken } from "services/Endpoints/EserviceBook/ERecordsEndpoint";
import { getVehicleWithAccessInviteAction } from "actions/VehicleAccessActions";
import { SMS_LINK_TYPE } from "utils/VehicleAccessConstants";
import { downloadAppClick } from "utils/SmsClickedUtils";
import { getERecordWithTokenAction } from "actions/ERecordActions";
export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "1",
    title: "Proizvod",
    menus: [
      { href: "/mobilna-aplikacija", label: "Mob app" },
      { href: "/kako-funkcionise", label: "Kako funkcioniše" },
      { href: "/adresar", label: "Adresar" },
    ],
  },
  {
    id: "2",
    title: "Pomoć",
    menus: [
      { href: "/pitanja", label: "Pitanja" },
      { href: "/kontakt", label: "Kontakt" },
    ],
  },
  {
    id: "3",
    title: "Uslovi",
    menus: [
      { href: "/politika-privatnosti", label: "Politika privatnosti" },
      { href: "/uslovi-koriscenja", label: "Uslovi korišćenja" },
    ],
  },
  // {
  //     id: "4",
  //     title: "Usluge",
  //     menus: [{ href: "/usluge", label: "Sve usluge" }],
  // },
];

const Footer: React.FC = () => {
  const [eRecord, setERecord] = useState<ERecord>();
  const [vehicle, setVehicle] = useState<Vehicle>();

  const location = useLocation();
  const url = location.pathname.split("-")[0];
  useEffect(() => {
    const fetchVehicleData = async (vehAccessInviteId: string) => {
      const responseData = await getVehicleWithAccessInviteAction(
        vehAccessInviteId
      );
      if (responseData.success) {
        setVehicle(responseData.value);
      }
    };
    const fetchERecordData = async (token: string) => {
        const responseData = await getERecordWithTokenAction(token);
        if (responseData.success) {
            setERecord(responseData.value);
        }
    };

    if (url === "/r") {
      fetchERecordData(location.pathname.split("-")[1]);
    }
    if (url === "/i") {
      fetchVehicleData(location.pathname.split("-")[1]);
    }
  }, []);

  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm ">
        <h2 className="font-semibold text-neutral-700 ">{menu.title}</h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <Link
                key={index}
                className="text-neutral-6000 hover:text-black"
                to={item.href}
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-4 pb-10 lg:py-18 border-t border-neutral-200">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-3 sm:gap-x-8 md:grid-cols-6 lg:grid-cols-6 lg:gap-x-7 ">
        <div className="grid   gap-3 col-span-1 md:col-span-1 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-1 justify-self-center md:col-span-2 ">
            <Logo img={blueLogo} />
          </div>
        </div>

        <div className="max-w-sm col-span-1 mt-2 md:col-span-1  text-sm space-y-4">
          <div className="h-auto  md:block  space-between space-x-3 mt-6">
            <a
              className="cursor-pointer"
              onClick={() => downloadAppClick(true)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="max-w-[120px]" src={btnAndroidPng} alt="" />
            </a>
            <a
              className="cursor-pointer"
              onClick={() => downloadAppClick(false)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="max-w-[120px]" src={btnIosPng} alt="" />
            </a>
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>
    </div>
  );
};

export default Footer;
