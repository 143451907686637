import { getServiceTypesAction } from "actions/BusinessesActions";
import { setServiceTypesArrayRedux } from "actions/serviceActions";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "reducers";
import { ServiceTypesArray } from "reducers/serviceReducer";
import FullScreenLoading from "routers/FullScreenLoading";

interface ExpandedSections {
  [key: number]: boolean; // The key is a number and the value is a boolean
}

const ServicingPage = () => {
  const [serviceTypes, setServiceTypes] = useState<ServiceTypesArray[]>(
    useSelector((state: RootState) => state.service.serviceTypes)
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    handleResize(); // Initial check

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const calculateGridSpan = (
    serviceTypes: ServiceTypesArray[],
    x: {
      header: ServiceTypesArray;
      items: ServiceTypesArray[];
    }
  ) => {
    const length = serviceTypes.filter(
      (y) => y.service_ID === x.header.id
    ).length;

    return length + 2;
  };

  function organizeLevel2Objects(
    userObjects: ServiceTypesArray[]
  ): { header: ServiceTypesArray; items: ServiceTypesArray[] }[] {
    const sections: {
      header: ServiceTypesArray;
      items: ServiceTypesArray[];
    }[] = [];

    if (!userObjects.length) {
      return [];
    }
    const level2Objects = userObjects.filter((obj) => obj.level === 2);
    level2Objects.forEach((level2) => {
      const level3Objects = userObjects.filter(
        (obj) => obj.service_ID === level2.id && obj.level === 3
      );
      sections.push({
        header: level2,
        items: level3Objects,
      });
    });

    return sections;
  }

  const sekcije2 = organizeLevel2Objects(serviceTypes);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchServiceTypes = async () => {
        setLoading(true);
        const items = await getServiceTypesAction(dispatch);
        if (items && items.length > 0)
        {
            setServiceTypes(items);
        }
        setLoading(false);
    };
    const initialExpandedSections: ExpandedSections = {};
    const sections = organizeLevel2Objects(serviceTypes);
    sections.forEach((section) => {
      if (section.items.length) {
        initialExpandedSections[section.header.id] = true;
      }
    });
    fetchServiceTypes();
  }, []);

  if (loading) {
    return <FullScreenLoading />;
  }

  return (
    <div
      className="nc-SectionBecomeAnAuthor p-4 items-center "
      data-nc-id="SectionBecomeAnAuthor"
    >
      <h2 className="font-semibold text-4xl my-8  text-center">Sve usluge</h2>
      <div
        style={{
          display: "grid",
          gridGap: "10px",
          gridTemplateColumns: isMobile ? "1fr" : "repeat(4, minmax(0, 1fr))",
        }}
      >
        {sekcije2.map((section) => {
          return (
            <div
              key={section.header.id}
              style={{
                gridRow: `span ${calculateGridSpan(serviceTypes, section)}`,
              }}
            >
              <h4 className="font-semibold ">
                <span
                  onClick={() =>
                    navigate("/adresar", { state: { ...section.header } })
                  }
                  className="block font-bold p-2 pl-0 rounded-lg cursor-pointer mt-2 flex items-center"
                >
                  {section.header.name}
                </span>
              </h4>
              <ul className="text-neutral-600 rounded-lg mt-1">
                {section.items.map((item) => (
                  <li key={item.id} className="mb-2">
                    <div
                      onClick={() =>
                        navigate("/adresar", { state: { ...item } })
                      }
                      className="flex cursor-pointer justify-between items-center space-x-4 rounded-lg"
                    >
                      <span>{item.name}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ServicingPage;
