import { FC, useEffect, useState } from "react";
import Label from "components/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { contactInfo } from "data/types";
import * as Yup from "yup";
import { Helmet } from "react-helmet";
import contactForm from "images/contactForm.png";
import { contactUsAction } from "actions/SupportActions";

export interface ContactPageProps {
  className?: string;
}

const formValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("Unesite ime i prezime"),
  mobile: Yup.string()
    .min(9, "Broj telefona mora da sadrži minimalno 9 cifara")
    .required("Unesite broj telefona"),
  email: Yup.string().email("Unesite email").required("Email je obavezan"),
  companyName: Yup.string().required("Unesite naziv firme"),
  message: Yup.string()
    .min(10, "Poruka mora da sadrži minimalno 10 karaktera")
    .required("Poruka je obavezna"),
});

const ContactPage: FC<ContactPageProps> = ({ className = "" }) => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState({
    firstName: "",
    mobile: "",
    email: "",
    companyName: "",
    message: "",
  });
  const [formData, setFormData] = useState({
    firstName: "",
    mobile: "",
    email: "",
    companyName: "",
    message: "",
  });

  useEffect(() => {
    if (isSubmitted) {
      const timer = setTimeout(() => {
        setIsSubmitted(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isSubmitted]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = async () => {
    try {
      await formValidationSchema.validate(formData, { abortEarly: false });
      setErrorMessage({
        firstName: "",
        mobile: "",
        email: "",
        companyName: "",
        message: "",
      });
      return true;
    } catch (validationErrors) {
      //@ts-ignore
      const newErrors = validationErrors.inner.reduce((acc, error) => {
        acc[error.path] = error.message;
        return acc;
      }, {});
      setErrorMessage(newErrors);
      return false;
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsSubmitted(false);
    const data = {
      firstname: formData.firstName,
      lastName: "",
      email: formData.email,
      mobile: formData.mobile,
      title: formData.companyName,
      message: formData.message,
      source: "web", //hardkodovan property
    };
    const valid = await validateForm();
    if (valid) {
        const response = await contactUsAction(data);
        if (response.success) {
            setIsSubmitted(true);
            setFormData({
              firstName: "",
              mobile: "",
              email: "",
              companyName: "",
              message: "",
            });
        }
    }
  };

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Kontakt</title>
        <meta property="og:title" content="Kontakt"></meta>
      </Helmet>
      <div className="mb-12">
        <h2 className="my-8 mb-16 flex items-center leading-[115%] text-4xl md:leading-[115%] font-semibold text-neutral-900 justify-center">
          Kontakt
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8 ">
              <img width="auto" height="full" src={contactForm} alt="" />
              <div>
                Ukoliko imate pitanja možete nas kontaktirati putem maila,
                trudićemo se da odgovorimo u najkraćem roku.
              </div>
              {contactInfo.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm tracking-wider">
                    {item.title}
                  </h3>
                  {item.title === "💌 Email" ? (
                    <a
                      href="mailto:office@eknjizica.rs"
                      style={{
                        color: "blue",
                        fontWeight: "bold",
                        textDecoration: "underline",
                      }}
                    >
                      {" "}
                      <span className="block mt-2  ">{item.desc}</span>
                    </a>
                  ) : (
                    <span className="block mt-2 text-neutral-500 ">
                      {item.desc}
                    </span>
                  )}
                </div>
              ))}
            </div>
            <div>
              <form
                className="grid grid-cols-1 gap-6"
                action=""
                method="post"
                onSubmit={handleSubmit}
              >
                <label className="block">
                  <Label>Ime i prezime *</Label>
                  <Input
                    onChange={handleInputChange}
                    placeholder="npr. Petar Petrović"
                    type="text"
                    name="firstName"
                    className="mt-1"
                    value={formData.firstName}
                  />
                  {errorMessage.firstName && (
                    <span style={{ color: "red" }}>
                      {errorMessage.firstName}
                    </span>
                  )}
                </label>
                <label className="block">
                  <Label>Telefon *</Label>
                  <Input
                    onChange={handleInputChange}
                    placeholder="npr. 060 123-456"
                    type="text"
                    name="mobile"
                    className="mt-1"
                    value={formData.mobile}
                  />
                  {errorMessage.mobile && (
                    <span style={{ color: "red" }}>{errorMessage.mobile}</span>
                  )}
                </label>
                <label className="block">
                  <Label>Email *</Label>
                  <Input
                    onChange={handleInputChange}
                    type="email"
                    name="email"
                    placeholder="npr. mail@example.com"
                    className="mt-1"
                    value={formData.email}
                  />
                  {errorMessage.email && (
                    <span style={{ color: "red" }}>{errorMessage.email}</span>
                  )}
                </label>
                <label className="block">
                  <Label>Naziv firme *</Label>
                  <Input
                    onChange={handleInputChange}
                    placeholder="npr. Auto servis DOO"
                    type="text"
                    name="companyName"
                    className="mt-1"
                    value={formData.companyName}
                  />
                  {errorMessage.companyName && (
                    <span style={{ color: "red" }}>
                      {errorMessage.companyName}
                    </span>
                  )}
                </label>
                <label className="block">
                  <Label>Poruka *</Label>
                  <Textarea
                    onChange={handleInputChange}
                    placeholder="npr. Zainteresovani smo za besplatnu probu programa"
                    className="mt-1"
                    name="message"
                    rows={6}
                    value={formData.message}
                  />
                  {errorMessage.message && (
                    <span style={{ color: "red" }}>{errorMessage.message}</span>
                  )}
                </label>
                <div>
                  <ButtonPrimary type="submit">Pošalji poruku</ButtonPrimary>
                </div>
                {isSubmitted && (
                  <div className="text-green-600">
                    Poruka je poslata uspešno.
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
