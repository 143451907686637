/* eslint-disable no-unused-vars */

export const ENV_TYPES = Object.freeze({
    Development: 'development',
    Staging: 'staging',
    Production: 'production',
})

const DevConfig = Object.freeze({
    backendUrls: {
        superRegistracija: {
            urlBase: '',
        },
        eServiceBook: {
            urlBase: ' http://localhost:49929',
            storageUrl: 'https://eservicebookstoragetest.blob.core.windows.net/eservicebooktest',
            apiVersion: 1,
        },
    },
    environment: ENV_TYPES.Development,
    enableSentry: false,
    googlePlayUrl: 'https://play.google.com/store/apps/details?id=com.regossoftware.eservicebook',
    appleStoreUrl: 'https://www.apple.com/app-store/',
    features: {
        reviewSnippetEnabled: false,
    },
    appUrl: ''
})

const StagingConfig = Object.freeze({
    backendUrls: {
        superRegistracija: {
            urlBase: '',
        },
        eServiceBook: {
            urlBase: 'https://eservicebookapidrivertest.azurewebsites.net',
            storageUrl: 'https://eservicebookstoragetest.blob.core.windows.net/eservicebooktest',
            apiVersion: 1,
        },
    },
    environment: ENV_TYPES.Staging,
    fetchTimeout: 60000,
    enableSentry: true,
    sentryUrl: 'https://2af635565b4a91e72dbde38836a0873c@o399390.ingest.us.sentry.io/4507700822081536',
    googlePlayUrl: 'https://play.google.com/store/apps/details?id=com.regossoftware.eservicebook',
    appleStoreUrl: 'https://www.apple.com/app-store/',
    googleAnalyticsEnabled: true,
    googleAnalyticsSiteID: '',
    features: {
        reviewSnippetEnabled: false,
    },
    appUrl: ''
})

const ProdConfig = Object.freeze({
    backendUrls: {
        superRegistracija: {
            urlBase: '',
        },
        eServiceBook: {
            urlBase: 'https://eservicebook-api.azurewebsites.net',
            storageUrl: 'https://eservicebookstorage.blob.core.windows.net/eservicebook',
            apiVersion: 1,
        },
    },
    environment: ENV_TYPES.Production,
    fetchTimeout: 60000,
    enableSentry: true,
    sentryUrl: 'https://2af635565b4a91e72dbde38836a0873c@o399390.ingest.us.sentry.io/4507700822081536',
    googlePlayUrl: 'https://play.google.com/store/apps/details?id=com.regossoftware.esb',
    appleStoreUrl: 'https://apps.apple.com/us/app/e-knji%C5%BEica/id6737975252',
    googleAnalyticsEnabled: true,
    googleAnalyticsSiteID: 'G-2NSLY1KLWQ',
    features: {
        reviewSnippetEnabled: true,
    },
    appUrl: 'https://www.eknjizica.rs'
})

const AppConfig = ProdConfig
 //const AppConfig = DevConfig

export default AppConfig
